<template lang="pug">
  b-container(fluid)
    b-row
      b-col
        h3.mb-3 Perfiles
      b-col(cols="auto")
        b-button(variant="success", :to="{name: 'Perfil', params: {perfil_id: 'new'} }") Crear perfil
    b-table(:items="perfiles", :fields="fields", responsive, hover, no-border-collapse, @row-clicked="openRow", show-empty)
      template(#empty)
        .text-center No hay datos para mostrar
      template(#cell(createdAt)="{item}")
        span {{ $moment(item.createdAt).format('DD MMMM YYYY, HH:mmA') }}
      template(#cell(updatedAt)="{item}")
        span {{ $moment(item.updatedAt).format('DD MMMM YYYY, HH:mmA') }}
      template(#cell(name)="{item}")
        span {{ item.first_name }} {{ item.last_name }}
      template(#cell(car)="{item}")
        p.mb-1.font-weight-bold {{ item.placas }}
        p.mb-0 {{ item.marca }}
        p.mb-0 {{ item.submarca }}
      template(#cell(status)="{item}")
        b-badge(:variant="getStatusVariant(item.status)") {{ item.status || 'Sin status' }}
    .d-flex.align-items-center.mb-3
      b-pagination.mb-0(v-model="paginationPage", :total-rows="paginationTotal", :per-page="paginationLimit", @input="getPerfiles")
      b-spinner.ml-3(variant="primary", small, v-if="loadingData")
</template>

<script>
import { createNamespacedHelpers, mapActions } from 'vuex';
const perfilController = createNamespacedHelpers('perfil');

export default {
  data () {
    return {
      fields: [
        {
          key: 'perfil_id',
          label: 'Id'
        },
        {
          key: 'name',
          label: 'Nombre'
        },
        {
          key: 'relation_type',
          label: 'Relación'
        },
        'status',
        {
          key: 'car',
          label: 'Vehículo'
        },
        {
          key: 'updatedAt',
          label: 'Actualizado'
        },
        {
          key: 'createdAt',
          label: 'Creado'
        }
      ]
    }
  },
  computed: {
    ...perfilController.mapState({
      perfiles: state => state.perfiles
    })
  },
  methods: {
    ...mapActions(['perfil/all']),
    getStatusVariant (status) {
      switch (status) {
        case 'entrada':
          return 'success';
        case 'salida':
          return 'secondary';
        default:
          return 'light';
      }
    },
    getPerfiles () {
      this.loadingData = true;
      this['perfil/all']({
        params: {
          page: this.paginationPage,
          limit: this.paginationLimit
        },
        callback: res => {
          this.loadingData = false;

          if (res.success) {
            this.paginationTotal = res.total
            this.paginationHasNextPage = res.hasNextPage
          } else {
            this.$bvToast.toast(`Ocurrió un error obteniendo los perfiles. Por favor intenta de nuevo.`, {
              title: 'Ocurrió un error',
              variant: 'danger'
            })
          }
        }
      })
    },
    openRow (item) {
      this.$router.push({name: 'Perfil', params: {perfil_id: item.perfil_id} });
    }
  },
  mounted () {
    this.getPerfiles()
  }
}
</script>

<style>

</style>