var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',[_c('h3',{staticClass:"mb-3"},[_vm._v("Perfiles")])]),_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{attrs:{"variant":"success","to":{name: 'Perfil', params: {perfil_id: 'new'} }}},[_vm._v("Crear perfil")])],1)],1),_c('b-table',{attrs:{"items":_vm.perfiles,"fields":_vm.fields,"responsive":"","hover":"","no-border-collapse":"","show-empty":""},on:{"row-clicked":_vm.openRow},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center"},[_vm._v("No hay datos para mostrar")])]},proxy:true},{key:"cell(createdAt)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment(item.createdAt).format('DD MMMM YYYY, HH:mmA')))])]}},{key:"cell(updatedAt)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment(item.updatedAt).format('DD MMMM YYYY, HH:mmA')))])]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.first_name)+" "+_vm._s(item.last_name))])]}},{key:"cell(car)",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-1 font-weight-bold"},[_vm._v(_vm._s(item.placas))]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.marca))]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.submarca))])]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{attrs:{"variant":_vm.getStatusVariant(item.status)}},[_vm._v(_vm._s(item.status || 'Sin status'))])]}}])}),_c('div',{staticClass:"d-flex align-items-center mb-3"},[_c('b-pagination',{staticClass:"mb-0",attrs:{"total-rows":_vm.paginationTotal,"per-page":_vm.paginationLimit},on:{"input":_vm.getPerfiles},model:{value:(_vm.paginationPage),callback:function ($$v) {_vm.paginationPage=$$v},expression:"paginationPage"}}),(_vm.loadingData)?_c('b-spinner',{staticClass:"ml-3",attrs:{"variant":"primary","small":""}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }